<!-- 客户工作情况 -->
<template>
    <div>
        <Container title="客户工作情况" padding="15px 15px 20px 15px">
            <div slot="handle">
                <div class="handle-btn mr-15">
                    <el-button v-if="!isEdit" type="primary" @click="edit">编辑</el-button>
                    <el-button v-if="isEdit" @click="cancel">取消</el-button>
                    <el-button v-if="isEdit" :loading="isLoading" type="primary" @click="save">保存</el-button>
                </div>
            </div>
            <div>
                <!-- 编辑态 -->
                <div v-if="isEdit">
                    <el-form ref="formRef" :model="formModel" label-width="100px">
                        <div class="auxiliary-line" v-for="question in questions" :key="question.key">
                            <p>{{ question.question }}</p>
                            <el-form-item label-width="0" :prop="question.key">
                                <el-radio-group v-model="formModel[question.key]">
                                    <el-radio v-for="key in Object.keys(value_map).reverse()" :key="key" :label="key">{{ value_map[key] }}</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </div>
                    </el-form>
                </div>
                <!-- 只读态 -->
                <div v-else class="pdt-20">
                    <div v-for="question in questions" :key="question.key">
                        <p>{{ question.question }}</p>
                        <div>
                            <span>{{ value_map[originData[question.key]] || '-' }}</span>
                        </div>
                        <el-divider></el-divider>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</template>

<script>
import { getWorkSituation, updateWorkSituation } from "../../../../api/gifted"

export default {
    components: {},
    props: {},
    data() {
        return {
            isEdit: false,
            isLoading: false,
            value_map: {
                1: '是',
                0: '否',
            },
            questions: [
                {
                    key: 'industry_specialty',
                    question: '1、行业专业是否一致？',
                },
                {
                    key: 'industry_background',
                    question: '2、行业背景是否一致？',
                },
                {
                    key: 'business_connected_with_hk',
                    question: '3、公司业务与香港联系是否紧密？',
                },
                {
                    key: 'has_cross_border_business',
                    question: '4、公司是否有规模化跨境业务？',
                },
            ],
            // 只读态-数据
            originData: {},
            // 编辑态-数据
            formModel: {}
        }
    },
    mounted() {
        this.query()
    },
    methods: {
        edit() {
            this.isEdit = true;
        },
        cancel() {
            this.formModel = JSON.parse(JSON.stringify(this.originData));
            this.isEdit = false;
        },
        async query() {
            this.isLoading = true
            try {
                const { data } = await getWorkSituation({
                    order_id: this.$route.params.id
                });
                console.log('工作情况', data)
                if (!data) return
                this.originData = this.formatQueryData(data);
                this.formModel = this.formatQueryData(data)
            } finally {
                this.isLoading = false
            }
        },
        formatQueryData(data) {
            const obj = {}
            if (data.industry_specialty) {
                obj.industry_specialty = data.industry_specialty.answer
            }
            if (data.industry_background) {
                obj.industry_background = data.industry_background.answer
            }
            if (data.business_connected_with_hk) {
                obj.business_connected_with_hk = data.business_connected_with_hk.answer
            }
            if (data.has_cross_border_business) {
                obj.has_cross_border_business = data.has_cross_border_business.answer
            }
            return obj
        },
        save() {
            this.$refs.formRef.validate(async (valid) => {
                if (valid) {
                    this.isLoading = true
                    try {
                        await updateWorkSituation({
                            order_id: this.$route.params.id,
                            item: this.questions.reduce((acc, question) => ({
                            ...acc,
                            [question.key]: {
                                answer: this.formModel[question.key],
                                answer_remark: ''
                            }
                        }), {})
                        });
                        this.isEdit = false
                        this.originData = JSON.parse(JSON.stringify(this.formModel))
                    } finally {
                        this.isLoading = false
                    }
                }
            })

        }
    }
}
</script>

<style lang="less" scoped>
.container {
    position: relative;

    .title-btn {
        position: absolute;
        top: 4px;
        right: 15px;
    }
}

.auxiliary-line>p {
    position: relative;
    padding-left: 12px;
    font-size: 14px;
    color: #606266;

    &.no-required {
        &::before {
            content: "";
        }
    }
}

.industry-box {
    padding: 0 16px;
    position: relative;

    /deep/.el-radio__label {
        display: none;
    }
}
</style>
