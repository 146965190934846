<!--
 * @Author: filename
 * @Description: 家庭成员-子女信息
-->
<template>
    <div class="mt-20">
        <Container title="父母信息" padding="0">
            <div v-for="(item, i) in parent" :key="i" :id="'pdf-parent'+i">
                <div :class="['title-box', i > 0 ? 'mt-20' : '']">
                    <span>{{ i === 0 ? '父亲' : '母亲' }}</span>
                    <div class="title-btn">
                        <el-button v-if="!item.isEdit" type="primary" @click="editInfo(item)">编辑</el-button>
                        <el-button v-if="item.isEdit" :loading="item.isLoading" @click="cancel(item)">取消</el-button>
                        <el-button v-if="item.isEdit" type="primary" :loading="item.isLoading" @click="checkInfo(i)">保存</el-button>
                    </div>
                </div>
                <!-- 显示 -->
                <ParentDetail v-if="!item.isEdit" :data="item" />
                <!-- 编辑 -->
                <EditParent v-loading="item.isLoading" :ref="'parent'+ i" v-if="item.isEdit" :data="family.parent[i]" />
            </div>
        </Container>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import ParentDetail from './C/ParentDetail.vue'
import EditParent from './C/EditParent.vue'
import { postClientInfo } from '../../../../../../api/gifted'
export default {
    props: {
        data: {
            type: Object,
            default:() => {}
        },
    },
    components: {
        ParentDetail,
        EditParent
    },
    data() {
        return {
            parent: [this.data.father, this.data.mother]
        }
    },
    computed: {
        ...mapState('gifted', ['dataList']),
        ...mapState('previewPdfGifted', ['family'])
    },
    created() {
        this.parent.forEach((item, i) => {
            if(this.parent[i]){
                this.$set(this.parent[i], 'isEdit', false)
                this.$set(this.parent[i], 'isLoading', false)
            }
        })
        // pdf预览逻辑
        this.$store.dispatch('previewPdfGifted/setFamilyKeyArr', { key: 'parent', data: JSON.parse(JSON.stringify(this.parent)) })
    },
    methods: {
        // 保存
        async saveInfo(index) {
            try {
                this.parent[index].isLoading = true
                const parm = JSON.parse(JSON.stringify(this.data))
                parm[index === 0 ? 'father' : 'mother'] = this.$refs['parent'+index][0].ruleForm
                const { code } = await postClientInfo(parm)
                if (code === 200) {
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    })
                    this.parent[index].isEdit = false
                    parm[index === 0 ? 'father' : 'mother'].isEdit = false
                    this.parent[index] = parm[index === 0 ? 'father' : 'mother']
                    this.data[index === 0 ? 'father' : 'mother'] = parm[index === 0 ? 'father' : 'mother']
                }
            } finally {
                this.parent[index].isLoading = false
            }
        },
        // 编辑
        editInfo(item) {
            item.isEdit = true
        },
        // 保存
        checkInfo(index) {
            Promise.all([this.$refs['parent'+index][0].checkParm()])
            .then(async () => {
                this.saveInfo(index)
            })
            .catch((err) => {
                console.log(err)
                setTimeout(() => {
                    this.$utils.scrollIntoError(this)
                }, 100)
            })
            this.$utils.emptyModuleRed(this, 'pdf-bg2')
        },
        // 取消
        cancel(item) {
            this.$store.dispatch('previewPdfGifted/setFamilyKeyArr', { key: 'parent', data: JSON.parse(JSON.stringify(this.parent)) })
            item.isEdit = false
            // 清空标红
            this.$utils.emptyModuleRed(this, 'pdf-bg2')
        }
    }
}
</script>

<style lang="less" scoped>
</style>
