<!--
 * @Author: filename
 * @Description: 家庭成员 - 父母展示
-->

<template>
    <div class="details-module common-part">
        <el-row>
            <el-col :span="12" id="subset_name">
                <span>姓名：</span>
                <span>
                    {{ data.subset_name }}
                    <i v-if="data.deceased">(<i style="color: red;">已故</i>)</i>
                </span>
            </el-col>

            <template v-if="data.deceased">
                <el-col :span="12" id="subset_birthday">
                    <span>出生日期：</span>
                    <span>{{ data.birthday }}
                        <span v-if="data.birthday" style="color: red;">({{ ages(data.birthday) + '岁' }})</span></span>
                </el-col>
                <el-col :span="12" id="subset_address">
                    <span>出生城市：</span>
                    <span>{{ data.birth_place_aboard === 0 ? data.birth_place.area.join('') : data.birth_place.foreign }}</span>
                </el-col>
            </template>

            <template v-if="!data.deceased">
                <el-col :span="12" id="subset_married">
                    <span>婚姻状况：</span>
                    <span>{{ Number(data.married) | _married }}</span>
                </el-col>
                <el-col :span="12" id="subset_birthday">
                    <span>出生日期：</span>
                    <span>{{ data.birthday }}
                        <span v-if="data.birthday" style="color: red;">({{ ages(data.birthday) + '岁'}})</span></span>
                </el-col>
                <el-col :span="12" id="subset_address">
                    <span>出生城市：</span>
                    <span>{{ data.birth_place_aboard === 0 ? data.birth_place.area.join('') : data.birth_place.foreign }}</span>
                </el-col>
                <el-col :span="12" id="subset_occupation">
                    <span>是否在职：</span>
                    <span>{{ data.occupation }}</span>
                </el-col>
                <el-col :span="12" id="subset_occupation_name">
                    <span>职位名称：</span>
                    <span>{{ data.occupation_name }}</span>
                </el-col>
                <el-col :span="12" id="subset_live_country">
                    <span>现居住国家/地区：</span>
                    <span>{{ data.live_country }}</span>
                </el-col>
                <el-col :span="12" id="subset_name_pinyin">
                    <span>姓名拼音：</span>
                    <template v-if="data.subset_name_pinyin">
                        <span>{{ data.subset_name_pinyin.family_name }}{{ data.subset_name_pinyin.given_name }}</span>
                    </template>
                </el-col>
                <el-col :span="24">
                    <el-divider />
                </el-col>
                <el-col :span="12">
                    <span>是否办理过香港身份：</span>
                    <span class="red" v-if="data.child_has_hk_id.has">{{ data.child_has_hk_id.has == 'Y' ? '是' : '否' }}</span>
                </el-col>
                <el-col :span="12" v-if="data.child_has_hk_id.has == 'Y'" id="subset_hk_identity_card">
                    <span>香港身份证：</span>
                    <span>{{ data.hk_identity_card }}</span>
                </el-col>
            </template>
        </el-row>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {

        }
    },
    methods: {
        ages(str) {
            var r = str.match(/^(\d{1,4})(-|\/)(\d{1,2})\2(\d{1,2})$/);
            if (r == null) return false
            var d = new Date(r[1], r[3] - 1, r[4]);
            if (d.getFullYear() == r[1] && (d.getMonth() + 1) == r[3] && d.getDate() == r[4]) {
                var Y = new Date().getFullYear();
                return Y - r[1]
            }
        }
    },
}
</script>

<style lang="less" scoped>
.red {
    color: red;
}
</style>
