<!--
 * @Author: filename
 * @Description: 家庭成员-子女信息
-->
<template>
    <div class="mt-20">
        <Container title="子女信息" padding="0" id="children-top">
            <div id="children-top-name"></div>
            <div slot="handle">
                <div class="handle-btn">
                    <el-button @click="addChild(1)">新增-随行</el-button>
                    <el-button @click="addChild(2)">新增-不随行</el-button>
                </div>
            </div>
            <div class="description-box" id="children-top-score">
                <p>{{ data.component_score.children.answer }}，得分<span class="text-red">{{data.component_score.children.score}}</span>分；</p>
            </div>
            <div v-loading="isLoading" v-if="data.child.length > 0">
                <div v-for="(item, i) in data.child" :key="i" :id="'pdf-children' + i">
                    <div :class="['title-box', i > 0 ? 'mt-20' : '']" :id="'children'+i">
                        <span>
                            <el-tooltip v-if="item.finish_follow == 1" class="item" effect="dark" placement="top-start">
                                <span class="tooltip">补</span>
                                <template slot="content">
                                    <div class="content">
                                        <div>创建人： {{item.supplement_info.operator_name}}</div>
                                        <div>加入时间： {{item.supplement_info.created_at}}</div>
                                        <div>主合同任务： {{item.supplement_info.order_task_name}}</div>
                                    </div>
                                </template>
                            </el-tooltip>
                            子女{{ i+1 }}/{{data.child.length}}
                            &nbsp;&nbsp;
                            <el-switch
                                v-if="item.id"
                                @change="followChange($event, i)"
                                active-text="随行"
                                inactive-text="不随行"
                                v-model="item.follow"
                                :active-value="1"
                                :inactive-value="2">
                            </el-switch>

                            <div v-if="item.id && item.follow == 1" style="margin-left: 50px; display: inline-block; font-size: 14px;">
                                是否添加在《受养人声明》：
                                <el-switch
                                    @change="statementChange($event, i)"
                                    active-text="添加"
                                    inactive-text="不添加"
                                    v-model="item.statement"
                                    :active-value="1"
                                    :inactive-value="0">
                                </el-switch>
                            </div>
                        </span>
                        <div class="title-btn">
                            <!-- 显示状态下按钮 -->
                            <template v-if="!item.isEdit">
                                <el-popconfirm placement="top" title="确定删除？" @confirm="del(i)">
                                    <el-button slot="reference">删除</el-button>
                                </el-popconfirm>
                                &nbsp;
                                <el-button type="primary" @click="editInfo(item)">编辑</el-button>
                            </template>
                            <!-- 编辑状态下按钮 -->
                            <template v-else>
                                <el-button :loading="item.isLoading" @click="cancel(item, i)">取消</el-button>
                                <el-button type="primary" :loading="item.isLoading" @click="checkInfo(i)">保存</el-button>
                            </template>
                        </div>
                    </div>
                    <!-- 显示 -->
                    <ChildrenDetail v-if="!item.isEdit" :data="item" />
                    <!-- 编辑 -->
                    <EditChildren v-loading="item.isLoading" :ref="'children'+i" v-if="item.isEdit" :data="family.children[i]" />
                </div>
            </div>
            <div v-else class="no-data">暂无数据</div>
        </Container>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import ChildrenDetail from './C/ChildrenDetail.vue'
import EditChildren from './C/EditChildren.vue'
import { postClientInfo } from '../../../../../../api/gifted'
export default {
    props: {
        data: {
            type: Object,
            default:() => {}
        },
    },
    components: {
        ChildrenDetail,
        EditChildren
    },
    data() {
        return {
            isLoading: false,
            parm: {
                subset_name_pinyin: {
                    family_name: '',
                    given_name: ''
                },
                isEdit: true,
                isLoading: false,
                custody: '',
                relation: 4,
                follow: '',
                subset_name: '',
                nationality: '',
                used_name: '',
                birthday: '',
                birth_place_aboard: 0,
                birth_place: {
                    area: [],
                    country: '',
                    details: '',
                    foreign: ''
                },
                occupation: '',
                occupation_name: '',
                child_has_hk_id: {
                    has: ''
                },
                hk_identity_card: '',
                sex: '',
                married: '',
                // 随行
                certificates: {
                    cn_identity_card: {
                        img_back: '',
                        img_front: '',
                        issue_at: '',
                        issue_date_end_at: '',
                        issue_date_start_at: '',
                        number: ''
                    },
                    hk_macao_pass: {
                        img_back: '',
                        img_front: '',
                        issue_at: '',
                        issue_date_end_at: '',
                        issue_date_start_at: '',
                        number: ''
                    },
                    passport: {
                        img_back: '',
                        img_front: '',
                        issue_at: '',
                        issue_date_end_at: '',
                        issue_date_start_at: '',
                        number: ''
                    }
                },
                nows: '',
                child_stepchild: {
                    has: ''
                },
                child_in_hk: {
                    has: '',
                    info: ''
                },
                electronic_visa_date: '',
                hk_identity: '',
                live_country: '',
                is_handled: '',
                address: {
                    area: [],
                    country: '',
                    details: '',
                    foreign: ''
                },
                permanent_residence: 0,
                permanent_reside_year: '',
                permanent_reside_month: '',
            }
        }
    },
    computed: {
        ...mapState('gifted', ['dataList']),
        ...mapState('previewPdfGifted', ['family'])
    },
    created() {
        this.setIsEdit();
        // pdf预览逻辑
        this.$store.dispatch('previewPdfGifted/setFamilyKeyArr', { key: 'children', data: JSON.parse(JSON.stringify(this.data.child)) })
    },
    methods: {
        // 是否随行
        followChange(v, i) {
            if (v == 2) {
                this.data.child[i].statement = 0
            }else {
                this.data.child[i].statement = 1
            }
            this.saveInfo(i, v)
        },
        // 是否添加受养人声明
        statementChange (v, i) {
            this.saveInfo(i, v)
        },
        // set isEdit
        setIsEdit() {
            this.data.child.forEach((item, i) => {
                this.$set(this.data.child[i], 'isEdit', false)
                this.$set(this.data.child[i], 'isLoading', false)
            })
        },
        // 新增 type 1 随行 2 不随行
        addChild(type) {
            const isEditChild = this.data.child.filter(item => item.isEdit === true)
            if(isEditChild.length > 0) {
                this.$message.error("请先保存正在编辑或者新增的子女！");
                return
            }
            const parm = JSON.parse(JSON.stringify(this.parm))
            parm.follow = type
            this.data.child.push(parm)
            this.$store.dispatch('previewPdfGifted/setFamilyKeyArr', { key: 'children', data: JSON.parse(JSON.stringify(this.data.child)) })
            this.anchorPoint()
        },

        // 保存
        async saveInfo(index, v) {
            try {
                this.data.child[index].isLoading = true
                if (this.data.child[index].certificates.hk_macao_pass.number) {
                    this.data.child[index].is_handled = 1
                }
                const parm = JSON.parse(JSON.stringify(this.data))
                if (typeof v != 'number') {
                    parm.child[index] = this.$refs['children'+index][0].ruleForm
                    if (parm.child[index].certificates.hk_macao_pass.number) {
                        parm.child[index].is_handled = 1
                    }
                }
                const { code, data } = await postClientInfo(typeof v === 'number' ? this.data : parm)
                if (code === 200) {
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    })
                    this.data.child[index].isEdit = false
                    this.data.child = data.child
                    this.data.component_score = data.component_score
                    this.setIsEdit()
                    await this.$utils.saveGiftedStatus(this.$route.params.id)
                    //  处理基本信息问题
                    this.dataList[2].child.forEach((item, i) => {
                        this.$set(this.dataList[2].child[i], 'isEdit', false)
                    })
                    this.$store.dispatch('previewPdfGifted/setFamilyKeyArr', { key: 'children', data: JSON.parse(JSON.stringify(this.data.child)) })
                }
            } finally {
                this.data.child[index].isLoading = false
            }
        },
        // 编辑
        editInfo(item) {
            item.isEdit = true
        },
        // 保存
        checkInfo(index) {
            Promise.all([this.$refs['children'+index][0].checkParm()])
            .then(async () => {
                this.saveInfo(index)
            })
            .catch((err) => {
                console.log(err)
                setTimeout(() => {
                    this.$utils.scrollIntoError(this)
                }, 100)
            })
            this.$utils.emptyModuleRed(this, 'pdf-bg2')
        },
        // 取消
        cancel(item, index) {
            if (item.id) {
                item.isEdit = false
            } else {
                this.data.child.splice(index, 1)
            }
            this.$store.dispatch('previewPdfGifted/setFamilyKeyArr', { key: 'children', data: JSON.parse(JSON.stringify(this.data.child)) })
            this.$utils.emptyModuleRed(this, 'pdf-bg2')
        },
        // 删除
        async del(index) {
            try {
                this.isLoading = true
                this.data.child.splice(index, 1)
                const { code, data } = await postClientInfo(this.data)
                if (code === 200) {
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    })
                    this.$store.dispatch('previewPdfGifted/setFamilyKeyArr', { key: 'children', data: JSON.parse(JSON.stringify(this.data.child)) })
                }
                this.data.component_score = data.component_score
                await this.$utils.saveGiftedStatus(this.$route.params.id)
                //  处理基本信息问题
                this.dataList[2].child.forEach((item, i) => {
                    this.$set(this.dataList[2].child[i], 'isEdit', false)
                })
            } finally {
                this.isLoading = false
            }
        },
        // 新增锚点到新增相应的位置
        anchorPoint() {
            this.$nextTick(() => {
                const pageId = document.querySelector('#children' + (this.data.child.length - 1))
                pageId.scrollIntoView({
                    block: "start",
                    behavior: "smooth",
                })
            })
        },
    }
}
</script>

<style lang="less" scoped>
.tooltip{
    display: inline-block;
    border-radius: 100%;
    background: #1296db;
    color: #fff;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border: 1px #377395 solid;
    // height: 100%;
}
.content{
    padding: 3px;
    div{
        margin: 10px 0;
    }
}
</style>
