<template>
    <!-- 基本资格 -->
    <div>
        <div class="pdf-bg3">
            <div class="old18 yes">
                <img class="correct" src="@/assets/img/correct.png" alt="" />
            </div>
            <div class="ability yes">
                <img class="correct" src="@/assets/img/correct.png" alt="" />
            </div>
            <!-- 资产  -->
            <div class="property" v-if="property.property">
                <div v-for="(item,index) in property.property" :class="`property${index +1}`">
                    <div v-pdfbg:property_explain="{ value: item.item, id: 'user_property' }" class="explain">{{item.item}}</div>
                    <div v-pdfbg:property_explain="{ value: item.hkd, id: 'user_property' }" class="money">{{item.hkd}}</div>
                </div>
                <div class="propertyA">{{A.toFixed(4)}}</div>
            </div>
            <!-- 负债  -->
            <div class="liabilities" v-if="property.liabilities">
                <div v-for="(item,index) in property.liabilities" :class="`liabilities${index +1}`">
                    <div v-pdfbg:liabilities_explain="{ value: item.item, id: 'user_liabilities' }" class="explain">{{item.item}}</div>
                    <div v-pdfbg:liabilities_explain="{ value: item.hkd, id: 'user_liabilities' }" class="money">{{item.hkd}}</div>
                </div>
            </div>
            <div class="liabilitiesB">{{B.toFixed(2)}}</div>
            <div class="AB_C">{{C.toFixed(4)}}</div>
        </div>
        <div class="pdf-bg4">
            <div v-if="applicant.applicant" v-pdfbg:applicant1="{ value: applicant.applicant.has_conviction.info, id: 'user_applicant' }" class="applicant1">
                <img :class="['correct',applicant.applicant.has_conviction.has === 'Y'?'yes':applicant.applicant.has_conviction.has === 'N'?'no':'none']" src="@/assets/img/correct.png" alt="" />
                <div v-if="applicant.applicant.has_conviction.has === 'Y'" class="info">{{applicant.applicant.has_conviction.info}}</div>
            </div>
            <div v-if="applicant.applicant" v-pdfbg:applicant2="{ value: applicant.applicant.has_departure.info, id: 'user_applicant' }" class="applicant2">
                <img :class="['correct',applicant.applicant.has_departure.has === 'Y'?'yes':applicant.applicant.has_departure.has === 'N'?'no':'none']" src="@/assets/img/correct.png" alt="" />
                <div v-if="applicant.applicant.has_departure.has === 'Y'" class="info">{{applicant.applicant.has_departure.info}}</div>
            </div>
            <div v-if="applicant.applicant" v-pdfbg:applicant3="{ value: applicant.applicant.has_deny.info, id: 'user_applicant' }" class="applicant3">
                <img :class="['correct',applicant.applicant.has_deny.has === 'Y'?'yes':applicant.applicant.has_deny.has === 'N'?'no':'none']" src="@/assets/img/correct.png" alt="" />
                <div v-if="applicant.applicant.has_deny.has === 'Y'" class="info">{{applicant.applicant.has_deny.info}}</div>
            </div>
            <div v-if="applicant.applicant" v-pdfbg:applicant4="{ value: applicant.applicant.has_breaking_law.info, id: 'user_applicant' }" class="applicant4">
                <img :class="['correct',applicant.applicant.has_breaking_law.has === 'Y'?'yes':applicant.applicant.has_breaking_law.has === 'N'?'no':'none']" src="@/assets/img/correct.png" alt="" />
                <div v-if="applicant.applicant.has_breaking_law.has === 'Y'" class="info">{{applicant.applicant.has_breaking_law.info}}</div>
            </div>
            <div v-if="applicant.applicant" v-pdfbg:applicant5="{ value: applicant.applicant.has_other_id.info, id: 'user_applicant' }" class="applicant5">
                <img :class="['correct',applicant.applicant.has_other_id.has === 'Y'?'yes':applicant.applicant.has_other_id.has === 'N'?'no':'none']" src="@/assets/img/correct.png" alt="" />
                <div v-if="applicant.applicant.has_other_id.has === 'Y'" class="info">{{applicant.applicant.has_other_id.info}}</div>
            </div>
            <div v-pdfbg:language-top-content="{ value: '', id: 'language-top' }" class="applicant6">
                <img class="correct" src="@/assets/img/correct.png" alt="" />
            </div>
            <div v-pdfbg:language-top-content="{ id: 'language-top' }" class="language-type">
                {{ language.level | language}}<span v-if="language.level === 4">，{{language.name}}</span>
            </div>
            <div v-pdfbg:pdf-education-top="{ id: 'pdf-education' }" class="applicant7">
                <img class="correct" src="@/assets/img/correct.png" alt="" />
            </div>
            <div class="applicant8">
                <img class="correct" src="@/assets/img/correct.png" alt="" />
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    data() {
        return {
            A:0,//资产总额
            B:0,//负债总额
            C:0//A-B
        }
    },
    filters: {
        language(val) {
            switch (val) {
                case 1:
                    return '中文，英文'
                case 2:
                    return '中文，英文'
                case 4:
                    return '中文'
                case 5:
                    return '中文'
                case 6:
                    return '英文'
                default:
                    return '-';
            }
        }
    },
    computed: {
        ...mapState('previewPdfGifted', ['family']),
        ...mapState('previewPdfGifted', ['property']),
        ...mapState('previewPdfGifted', ['applicant']),
        ...mapState('previewPdfGifted', ['language'])
    },
    watch:{
        property:{
            handler(val){
                if(val.property){
                    console.log(';val', val);
                    this.A = 0;
                    this.B = 0;
                    this.C = 0;
                    val.property.forEach((arr,index) => {
                        this.A = this.A + Number(arr.hkd);
                        // 预览这里的合计是前端计算的，减去后端返回的合计字段。避免重复计算
                        if (arr.item == '合计' && arr.id == '') {
                            // val.property.splice(index, 1)
                            this.A = this.A - Number(val.property[index].hkd)
                        }
                    });
                    val.liabilities.forEach((arr,index) => {
                        // 预览页只有三行，隐藏后面数据
                        if(index<3){
                            this.B = this.B + Number(arr.hkd);
                        }
                         // 预览这里的合计是前端计算的，删除后端返回的合计字段。避免重复计算（负债只合计了前三行的数据
                        // if (arr.item == '合计' && arr.id == '') {
                        //     this.B = this.B - Number(val.liabilities[index].hkd)
                        // }
                    });
                    this.C = this.A - this.B;
                }
            },
            deep:true,
            immediate: true,
        }
    },
    created() {
    },
    methods: {

    }
}
</script>
<style lang="less" scoped>
.red {
    color: red;
}
.pdf-bg3,.pdf-bg4 {
    height: 1200px;
}
.pdf-bg3{
    position: relative;
    background: url('https://upload-cdn.galaxy-immi.com/public/img/981/ID981B-3.jpg') no-repeat;
    background-size: 100%;
    .yes{
        left: 63px;
    }
    .no{
        position: absolute;
        left: 415px;
    }
    .old18{
        position: absolute;
        top: 64px;
    }
    .ability{
        position: absolute;
        top: 164px;
    }
    .explain{
        position: absolute;
        left: 58px;
        width: 500px;
        height: 38px;
    }
    .money{
        position: absolute;
        height: 38px;
        left: 560px;
        width: 128px;
    }
    .property1{
        position: absolute;
        top: 287px;
    }
    .property2{
        position: absolute;
        top: 328px;
    }
    .property3{
        position: absolute;
        top: 369px;
    }
    .property4{
        position: absolute;
        top: 410px;
    }
    .property5{
        display: none;
    }
    .propertyA{
        position: absolute;
        top: 573px;
        left: 562px;
    }
    .liabilities1{
        position: absolute;
        top: 705px;
    }
    .liabilities2{
        position: absolute;
        top: 746px;
    }
    .liabilities3{
        position: absolute;
        top: 787px;
    }
    .liabilities4{
        display: none;
    }
    .liabilities5{
        display: none;
    }
    .liabilitiesB{
        position: absolute;
        top: 828px;
        left: 563px;
    }
    .AB_C{
        position: absolute;
        top: 878px;
        left: 563px;
    }
}
.pdf-bg4{
    position: relative;
    background: url('https://upload-cdn.galaxy-immi.com/public/img/981/ID981B-4.jpg') no-repeat;
    background-size: 100%;
    .yes{
        position: absolute;
        left: 59px;
        top: 22px;
    }
    .no{
        position: absolute;
        left: 406px;
        top: 22px;
    }
    .none{
        display: none;
    }
    .info{
        position: absolute;
        left: 42px;
        top: 58px;
        line-height: 36px;
    }
    .applicant1{
        position: absolute;
        top: 33px;
        left: 10px;
        width: 716px;
        height: 135px;
    }
    .applicant2{
        position: absolute;
        top: 174px;
        left: 10px;
        width: 716px;
        height: 135px;
    }
    .applicant3{
        position: absolute;
        top: 322px;
        left: 10px;
        width: 716px;
        height: 135px;
        .no{
            left: 400px;
        }
    }
    .applicant4{
        position: absolute;
        top: 466px;
        left: 10px;
        width: 716px;
        height: 135px;
        .yes{
            top: 25px;
        }
        .no{
            top: 25px;
            left: 397px;
        }
    }
    .applicant5{
        position: absolute;
        top: 613px;
        left: 10px;
        width: 716px;
        height: 135px;
        .yes{
            top: 40px;
        }
        .no{
            top: 40px;
            left: 393px;
        }
        .info{
            top: 59px;
        }
    }
    .applicant6{
        position: absolute;
        top: 760px;
        left: 8px;
        width: 719px;
        height: 56px;
        .correct{
            position: absolute;
            top: 24px;
            left: 60px;
        }
    }
    .language-type{
        position: absolute;
        top: 781px;
        left: 289px;
    }
    .applicant7{
        position: absolute;
        top: 821px;
        left: 8px;
        width: 718px;
        height: 52px;
        padding: 19px 0 0 60px;
    }
    .applicant8{
        position: absolute;
        top: 927px;
        left: 41px;
    }
}
.correct{
    width: 10px;
}
</style>
