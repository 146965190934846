<!--
 * @Author: filename
 * @Description: 家庭成员 - 兄弟姐妹编辑
-->

<template>
    <div class="common-part">
        <el-form :model="ruleForm" ref="ruleForm" label-width="145px">
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item
                        label="姓名："
                        prop="subset_name"
                        :rules="{ required: true, message: '请输入姓名', trigger: 'blur' }"
                    >
                        <el-input v-model="ruleForm.subset_name" clearable maxlength="50" placeholder="请输入对应的内容" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="婚姻状况：" prop="married" :rules="{ required: true, message: '请选择婚姻状况', trigger: 'change' }">
                        <el-select v-model="ruleForm.married" placeholder="请选择" clearable style="width: 100%;">
                            <el-option v-for="(item, index) in $CONFIG.MARRIED" :key="index" :label="item.label" :value="String(item.value)" />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="出生日期："
                        prop="birthday"
                        :rules="{ required: true, message: '请选择出生日期', trigger: 'change' }"
                    >
                        <el-date-picker
                            style="width: 100%;"
                            v-model="ruleForm.birthday"
                            value-format="yyyy-MM-dd"
                            type="date"
                            placeholder="年 -月 -日">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-row>
                        <el-col :span="16">
                            <el-form-item
                                label="出生城市："
                                prop="birth_place_aboard"
                                :rules="{ required: true, message: '请选择出生城市', trigger: 'change' }"
                            >
                                <el-radio-group v-model="ruleForm.birth_place_aboard" @change="handleSelect">
                                    <el-radio :label="0">国内</el-radio>
                                    <el-radio :label="1">国外</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8" v-if="ruleForm.birth_place_aboard === 0">
                            <el-form-item
                                class="form-item"
                                prop="birth_place.area"
                                :rules="{ required: true, message: '请选择出生城市', trigger: 'change' }"
                            >
                                <CitySelect v-model="ruleForm.birth_place.area"  style="width: 100%;" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8" v-if="ruleForm.birth_place_aboard === 1">
                            <el-form-item
                                class="form-item"
                                prop="birth_place.foreign"
                                :rules="{ required: true, message: '请输入城市', trigger: 'blur' }"
                            >
                                <el-input v-model="ruleForm.birth_place.foreign" clearable maxlength="100" placeholder="请输入城市" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="性别："
                        prop="sex"
                        :rules="{ required: true, message: '请选择性别', trigger: 'change' }"
                    >
                        <el-select v-model="ruleForm.sex" placeholder="请选择性别" clearable style="width: 100%">
                            <!-- <el-option label="未知" :value="0" /> -->
                            <el-option label="男" :value="1" />
                            <el-option label="女" :value="2" />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="职业："
                        prop="occupation"
                        :rules="{ required: true, message: '请选择职业', trigger: 'change' }"
                    >
                        <el-select v-model="ruleForm.occupation" clearable placeholder="请选择" style="width: 100%;" @change="handleOctChange">
                            <el-option
                                v-for="(item, i) in workOpts"
                                :key="i"
                                :label="item"
                                :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="现居住国家/地区："
                        prop="live_country"
                        :rules="{ required: true, message: '请填写现居住国家/地区', trigger: 'blur' }"
                    >
                        <CountrySelect v-model="ruleForm.live_country" placeholder="输入匹配国家" style="width: 100%" />
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-divider />
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        class="custom-width"
                        label="是否办理过香港身份证："
                        prop="child_has_hk_id.has"
                        :rules="{ required: true, message: '请选择', trigger: 'change' }"
                    >
                        <el-radio-group v-model="ruleForm.child_has_hk_id.has" @change="handleChang">
                            <el-radio label="Y">是</el-radio>
                            <el-radio label="N">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        class="custom-width"
                        v-if="ruleForm.child_has_hk_id.has === 'Y'"
                        label="香港身份证号码："
                        prop="hk_identity_card"
                        :rules="[{ required: true, message: '请输入香港身份证号码', trigger: 'blur' },
                                { required: true, trigger: 'blur', validator: $rules.validate.hkmIdSuffixNotRequired }]">
                        <!-- <el-input v-model.trim="ruleForm.hk_identity_card" clearable maxlength="20" placeholder="请输入香港身份证号码" /> -->
                        <HkIdCardInput v-model="ruleForm.hk_identity_card" suffix-required></HkIdCardInput>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import CnidForm from '../../../../../../CnidForm/CnidForm.vue'
import HkmPass from '../../../../../../HkmPass/HkmPass.vue';
import Passport from '../../../../../../Passport/Passport.vue';
import HkIdCardInput from '@/components/HkIdCardInput.vue'

export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },
    components: {
        CnidForm,
        HkmPass,
        Passport,
        HkIdCardInput
    },
    data() {
        return {
            ruleForm: {},
            workOpts: ["在职", "退休", "无职业", "学生"],
            workOpts: [
                "企业管理者（高级管理者） ",
                "企业管理者（包括初、中级层管理者）",
                "自主创业（有实际运营的公司）",
                "普通职员（办公室/写字楼）",
                "专业人员（医生/教师/律师/记者/文体）",
                "个体经营者/承包商",
                "政府机关干部/公务人员",
                "在校学生",
                "家庭主妇",
                "退休人员",
                "应届毕业生",
                "其他"
            ]
        }
    },
    created() {
        this.ruleForm = this.data
        for(let key in this.ruleForm) {
            if (this.ruleForm[key] == 100) {
                this.ruleForm[key] = ''
            }
        }
    },
    methods: {
        // 校验
        checkParm() {
            const ruleFormArray = []
            // 字段校验
            ruleFormArray.push(this.$refs.ruleForm.validate())
            const isCheck = Promise.all(ruleFormArray)
            return isCheck
        },
        // 是否办理过香港身份证
        handleChang() {
            // this.ruleForm.hk_identity_card = ''
        },
        // 出生城市选择
        handleSelect(v) {
            if (v===0) {
                this.ruleForm.birth_place.foreign = ''
            } else {
                this.ruleForm.birth_place.area = []
            }
        },
        // 是否在职
        handleOctChange(v) {
            if (v !== '在职') {
                this.ruleForm.occupation_name = ''
            }
        },
        // 添加学位
        addDegree() {
            this.ruleForm.has_degree_json.push({
                degree: '',
                address: ''
            })
        },
        // 删除学位
        delDegree(i) {
            this.ruleForm.has_degree_json.splice(i, 1)
        },
        // 在港身份
        handleHkIdentityChange(v) {
            if (v !== '其他') {
                this.ruleForm.hk_identity_other = ''
            }
        }
    }
}
</script>

<style lang="less" scoped>
.form-item {
    /deep/ .el-form-item__content {
        margin-left: 0 !important;
    }
}
.add-degree {
    span {
        display: inline-block;
        border: 1px solid #aaa;
        padding: 2px 8px;
        border-radius: 20px;
        color: #aaa;
        font-weight: bold;
        font-size: 20px;
        cursor: pointer;
    }
}
.custom-width {
    /deep/ .el-form-item__label {
        width: auto !important;
    }
    /deep/ .el-form-item__label {
        padding: 0 7px 0 0;
    }
}
</style>
