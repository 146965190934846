import { render, staticRenderFns } from "./EducationDetail.vue?vue&type=template&id=a28b4bca&scoped=true&"
import script from "./EducationDetail.vue?vue&type=script&lang=js&"
export * from "./EducationDetail.vue?vue&type=script&lang=js&"
import style0 from "./EducationDetail.vue?vue&type=style&index=0&id=a28b4bca&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a28b4bca",
  null
  
)

export default component.exports