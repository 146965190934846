<!--
 * @Author: filename
 * @Description: 家庭成员 - 兄弟姐妹展示
-->

<template>
    <div class="details-module common-part">
        <el-row>
            <el-col :span="12" id="subset_name">
                <span>姓名：</span>
                <span>
                    {{ data.subset_name }}
                </span>
            </el-col>
            <el-col :span="12" id="subset_married">
                <span>婚姻状况：</span>
                <span>{{ Number(data.married) | _married }}</span>
            </el-col>
            <el-col :span="12" id="subset_birthday">
                <span>出生日期：</span>
                <span>{{ data.birthday }}
                    <span v-if="data.birthday" style="color: red;">({{ ages(data.birthday) + '岁' }})</span></span>
            </el-col>
            <el-col :span="12" id="subset_address">
                <span>出生城市：</span>
                <span>{{ data.birth_place_aboard === 0 ? data.birth_place.area.join('') : data.birth_place.foreign }}</span>
            </el-col>
            <el-col :span="12" id="subset_sex">
                <span>性别：</span>
                <span>{{ data.sex | _sex }}</span>
            </el-col>
            <el-col :span="12" id="subset_occupation">
                <span>职位：</span>
                <span>{{ data.occupation }}</span>
            </el-col>
            <el-col :span="12" id="subset_live_country">
                <span>现居住国家/地区：</span>
                <span>{{ data.live_country }}</span>
            </el-col>
            <el-col :span="24">
                <el-divider />
            </el-col>
            <el-col :span="12">
                <span>是否办理过香港身份：</span>
                <span class="red" v-if="data.child_has_hk_id.has">{{ data.child_has_hk_id.has == 'Y' ? '是' : '否' }}</span>
            </el-col>
            <el-col :span="12" v-if="data.child_has_hk_id.has == 'Y'" id="subset_hk_identity_card">
                <span>香港身份证：</span>
                <span>{{ data.hk_identity_card }}</span>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
        }
    },
    methods: {
        ages(str) {
            var r = str.match(/^(\d{1,4})(-|\/)(\d{1,2})\2(\d{1,2})$/);
            if (r == null) return false
            var d = new Date(r[1], r[3] - 1, r[4]);
            if (d.getFullYear() == r[1] && (d.getMonth() + 1) == r[3] && d.getDate() == r[4]) {
                var Y = new Date().getFullYear();
                return Y - r[1]
            }
        }
    },
}
</script>

<style lang="less" scoped>
.red {
    color: red;
}
</style>
