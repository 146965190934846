<template>
  <div>
      <div
          v-for="(item, index) in data.works"
          :key="index"
          class="mb-20"
          :id="'pdf-works' + index"
      >
          <div class="details-module" :id="'scrollWorks' + index">
              <div class="box-title">
                  <div class="tit">
                      工作
                      <span v-if="index === 0"
                          >{{ index + 1 }}/{{ data.works.length }} (<span
                              style="color: red"
                              >最早工作</span
                          >)</span
                      >
                      <span
                          v-else-if="
                              index === data.works.length - 1 &&
                              data.works.length !== 1
                          "
                          >{{ index + 1 }}/{{ data.works.length }} (<span
                              style="color: red"
                              >最近工作</span
                          >)</span
                      >
                      <span v-else
                          >{{ index + 1 }}/{{ data.works.length }}</span
                      >
                  </div>
                  <div class="synthetic">
                      <el-switch
                          v-if="item.id"
                          @change="syntheticChange(item)"
                          active-text="合成"
                          inactive-text="不合成"
                          v-model="item.is_synthetic"
                          :active-value="1"
                          :inactive-value="2"
                      >
                      </el-switch>
                  </div>
                  <div class="title-btn">
                      <el-popconfirm
                          v-if="!item.isEdit"
                          placement="top"
                          title="确定删除？"
                          @confirm="del(index)"
                          class="mr-20"
                      >
                          <el-button slot="reference">删除</el-button>
                      </el-popconfirm>
                      <el-button
                          v-if="!item.isEdit"
                          type="primary"
                          @click="editInfo(item)"
                          >编辑</el-button
                      >
                      <el-button v-if="item.isEdit" @click="cancel(index)"
                          >取消</el-button
                      >
                      <el-button
                          v-if="item.isEdit"
                          type="primary"
                          :loading="isLoading"
                          @click="saveInfo(item, index)"
                          >保存</el-button
                      >
                  </div>
              </div>
              <div v-if="!item.isEdit" class="common-part">
                  <el-row class="clu">
                      <el-col :span="12" id="pdf-company_name">
                          <span>用人单位: </span>
                          <span>
                              {{ item.company_name }}
                              <label
                                  v-if="item.enterprises_type"
                                  style="color: #67c23a"
                                  >({{ item.enterprises_type === 2 ? '二类名企' : '一类名企' }})</label
                              >
                          </span>
                      </el-col>
                      <el-col :span="12" id="pdf-company_website">
                          <span>用人单位官网: </span>
                          <span>{{ item.company_website || '-' }}</span>
                      </el-col>
                  </el-row>
                  <el-row class="clu">
                      <el-col :span="12" id="pdf-company_introduce">
                          <span>用人单位介绍: </span>
                          <div v-if="isTextOverflowed">
                            <el-tooltip :content="item.company_introduce" placement="top" effect="light" popper-class="tool-tip">
                                <span class="text_line">{{ item.company_introduce || '-' }}</span>
                            </el-tooltip>
                           </div>
                            <div v-else class="text_line">
                                {{ item.company_introduce }}
                            </div>
                      </el-col>
                      <el-col :span="12" id="pdf-industry">
                          <span>用人单位性质: </span>
                          <span>{{ item.industry }}</span>
                      </el-col>
                  </el-row>
                  <el-row class="clu" v-if="item.industry === '其他（请备注）'">
                    <el-col :span="12"></el-col>
                    <el-col :span="12">
                        <span>备注: </span>
                        <span>{{ item.business_remark }}</span>
                    </el-col>
                  </el-row>
                  <el-row>
                      <el-col :span="12" id="pdf-country">
                          <span>工作地点:</span>
                          <span>{{
                              item.location_aboard === 1
                                  ? item.location.country +
                                    "-" +
                                    item.location.foreign
                                  : item.location.country +
                                    "-" +
                                    item.location.area.join("-")
                          }}</span>
                      </el-col>
                      <el-col :span="12" id="pdf-position">
                          <span>职位:</span>
                          <span>{{ item.position }}</span>
                      </el-col>
                  </el-row>
                  <el-row>
                      <el-col :span="12" id="pdf-entry_time">
                          <span>入职年月:</span>
                          <span>{{ item.entry_time }}</span>
                      </el-col>
                      <el-col :span="12" id="pdf-departure_time">
                          <span>离职年月:</span>
                          <span>{{
                              item.departure_time_checked
                                  ? "至今"
                                  : item.departure_time
                          }}</span>
                      </el-col>
                  </el-row>
                  <el-row class="clu">
                      <el-col :span="12">
                          <span>证明文件:</span>
                          <span>{{ item.work_certificate }}</span>
                      </el-col>
                      <el-col :span="12" id="pdf-duty">
                          <span>职业性质:</span>
                          <span>{{ item.duty }}</span>
                      </el-col>
                  </el-row>
                  <el-row class="clu">
                      <el-col :span="12" id="pdf-match_listing">
                          <span>符合清单:</span>
                          <span>{{ item.match_listing ? "是" : "否" }}</span>
                      </el-col>
                      <el-col :span="12">
                          <span>相关工作属国际工作经验:</span>
                          <span>{{ item.international_work_experience ? "有" : "没有" }}</span>
                      </el-col>
                  </el-row>
                  <el-divider></el-divider>
                  <el-row>
                      <el-col :span="12">
                          <span>企业人数规模: </span>
                          <span>{{ item.company_member_total }}</span>
                      </el-col>
                      <el-col :span="12">
                          <span>行政架构层级: </span>
                          <span>{{
                              item.administrative_level | administrativeName
                          }}</span>
                      </el-col>
                  </el-row>
                  <el-row>
                      <el-col :span="12">
                          <span>下属管理人数:</span>
                          <span>{{ item.company_manage_total }}</span>
                      </el-col>
                      <el-col :span="12">
                          <span>公司营业额/港元（近一年）:</span>
                          <span>{{ item.turnover}}</span>
                      </el-col>
                  </el-row>
                  <el-row>
                      <el-col :span="12" id="pdf-professional_level">
                          <span>职位水平类别:</span>
                          <span>{{item.professional_level | professionalName}}</span>
                      </el-col>
                      <el-col :span="12">
                          <span>公司业务性质/范畴/所属行业:</span>
                          <span>{{item.business_nature || "-"}}</span>
                      </el-col>
                  </el-row>
                  <el-row>
                      <el-col :span="12">
                          <span>重要项目负责人:</span>
                          <span>{{item.master_of_important_project| importantProjectName}}</span>
                      </el-col>
                      <el-col :span="12">
                          <span>高管证明文件:</span>
                          <span>{{ item.executives_certificate }}</span>
                      </el-col>
                  </el-row>
                  <el-row v-if="[1,2,3].includes(item.professional_level)">
                      <el-col :span="12">
                          <span>高管在职时间:</span>
                          <span
                              >{{ item.top_manager_date_string }}
                              <span style="margin-left: 10px"
                                  >{{ item.top_manager_date_long }}个月</span
                              ></span
                          >
                      </el-col>
                  </el-row>
                  <el-divider></el-divider>
                  <section v-for="projectItem in item.projects" :key="projectItem.id">
                    <el-row>
                        <el-col :span="12">
                            <span>项目名称:</span>
                            <span>{{ projectItem.project_name || '-' }}</span>
                        </el-col>
                        <el-col :span="12">
                            <span>项目日期:</span>
                            <span v-if="projectItem.project_time.length">{{ projectItem.project_time[0] }} - {{ projectItem.project_time[1] }}</span>
                            <span v-else>-</span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col>
                            <span>项目业绩/亮点:</span>
                            <span style="max-height: 200px;overflow-y: auto;">{{ projectItem.project_highlights || '-' }}</span>
                        </el-col>
                    </el-row>
                    <el-divider></el-divider>
                  </section>
                  <el-row class="clu">
                    <el-col :span="24">
                      <span>工作职责: </span>
                      <span
                        v-html="
                          item.wduty
                            ? item.wduty.replace(
                                /\n/gm,
                                `<br style='display: block; content: close-quote;'>`
                              )
                            : ''
                        "
                      ></span>
                    </el-col>
                  </el-row>
                  <el-row class="clu">
                    <el-col :span="24">
                      <span>工作亮点:</span>
                      <div
                        v-html="
                          item.highlights
                            ? item.highlights.replace(
                                /\n/gm,
                                `<br style='display: block; content: close-quote;'>`
                              )
                            : ''
                        "
                      ></div>
                    </el-col>
                  </el-row>
                  <el-row class="clu">
                      <el-col :span="24">
                          <span>文件附件:</span>
                          <div class="file-con">
                              <div
                                  v-for="(ite, ind) in item.file"
                                  :key="ind"
                                  class="mr-20"
                              >
                                  {{ ind + 1 }}、{{ ite.fileName
                                  }}<el-button
                                      @click="toView(ite)"
                                      type="text"
                                      style="margin-left: 20px"
                                      >查看</el-button
                                  ><el-button
                                      @click="download(ite)"
                                      type="text"
                                      style="margin-left: 10px"
                                      >下载</el-button
                                  >
                              </div>
                          </div>
                      </el-col>
                  </el-row>
              </div>
          </div>
          <!-- 编辑 -->
          <Edit
              v-if="item.isEdit"
              v-loading="isLoading"
              :ref="'editForm' + index"
              :id="'works' + index"
              :data="data"
              :index="index"
              class="pl-15"
          />
      </div>
      <el-dialog
          :title="isDeleteDocument ? '文件删除确认' : '文书更新提醒'"
          :visible.sync="dialogVisible"
          :close-on-click-modal="false"
          :before-close="beforeCloseDialog"
          width="750px"
      >
          <div class="update" v-if="isUpdateDocument">
              <main v-if="addDocument.length">
                  <div class="update-title">新增文书</div>
                  <div
                      class="document-name"
                      v-for="li in addDocument"
                      :key="li.id"
                  >
                      {{ li.new_work_certificate }}<span>新</span>
                  </div>
              </main>
              <main v-if="updateDocument.length">
                  <div class="update-title">更新文书名称</div>
                  <div
                      class="update-name"
                      v-for="li in updateDocument"
                      :key="li.id"
                  >
                      <el-tooltip :content="li.past_work_certificate">
                          <div class="old-name">
                              {{ li.past_work_certificate }}
                          </div>
                      </el-tooltip>
                      <div class="arrow"></div>
                      <el-tooltip :content="li.new_work_certificate">
                          <div class="new-name">
                              {{ li.new_work_certificate }}
                          </div>
                      </el-tooltip>
                  </div>
              </main>
          </div>
          <div class="update" v-if="isDeleteDocument">
              <div class="delete-tip">
                  因工作信息变更是否删除以下关联文件？
              </div>
              <li
                  class="delete-item"
                  v-for="li in deleteDocument"
                  :key="li.id"
              >
                  {{ li.template_name }}
              </li>
          </div>
          <span slot="footer" class="dialog-footer">
              <el-button
                  type="primary"
                  @click="confirmTheUpdate"
                  v-if="isUpdateDocument"
                  >确 认</el-button
              >
              <el-popconfirm
                  title="确认删除文书吗？"
                  @confirm="deleteDocumentFun"
                  v-if="isDeleteDocument"
              >
                  <el-button slot="reference">删除</el-button>
              </el-popconfirm>
              <el-button
                  style="margin-left: 16px"
                  type="primary"
                  @click="goToTheList"
                  v-if="isDeleteDocument"
                  >前往清单处理</el-button
              >
          </span>
      </el-dialog>
  </div>
</template>

<script>
import Edit from "./Edit.vue";
import { fileService } from '../../../../../../../utils/file-service'
import {
  postClientInfo,
  configurationListDel,
  getFlowTree,
} from "../../../../../../../api/gifted";
import { mapState } from 'vuex'
export default {
  components: {
      Edit,
  },
  props: {
      initData: {
          type: Object,
          default: () => {
              return {};
          },
      },
  },
  filters: {
      administrativeName(val) {
          switch (val) {
              case 1:
                  return "组织架构最顶级";
              case 2:
                  return "组织架构第二层级";
              case 3:
                  return "组织架构第三层级";
              case 4:
                  return "组织架构第四层级";
              case 10:
                  return "以上都不是";
              default:
                  return "-";
          }
      },
      professionalName(val) {
          switch (val) {
              case 5:
                  return "半专业人士";
              case 4:
                  return "专业人士";
              case 3:
                  return "初级经理人";
              case 2:
                  return "中级经理人";
              case 1:
                  return "高级经理人";
              case 6:
                  return "其他";
              default:
                  return "-";
          }
      },
      importantProjectName(val) {
          switch (val) {
              case 1:
                  return "是";
              case 2:
                  return "否";
              default:
                  return "-";
          }
      },
  },
  data() {
      return {
          oldData: [],
          isLoading: false,
          documentDialogTitle: "文书更新提醒",
          dialogVisible: false,
          isUpdateDocument: false, // 是否是更新或者新增文书
          isDeleteDocument: false, // 是否有删除文书
          updateDocument: [],
          addDocument: [],
          deleteDocument: {},
          treeData: [],
          data: {
              works: []
          },
          isTextOverflowed: false
      };
  },
  mounted() {
      this.data = this.initData
      this.data.works.forEach((item, i) => {
          this.$set(this.data.works[i], "isEdit", false);
      });
      this.oldData = JSON.parse(JSON.stringify(this.data.works));
      this.getFlowTreeFun();
      this.$nextTick(() => {
        const element = this.$el.querySelector(".text_line");
        if (element) {
        // 计算元素的行高
        const lineHeight = parseInt(getComputedStyle(element).lineHeight);

        // 计算元素的高度
        const height = element.scrollHeight;
        // 计算文本的实际行数
        const lines = height / lineHeight;
        // 检查文本是否超过最大行数
        if (lines > 3) {
          // 超过最大行数，可以采取相应的操作
          this.isTextOverflowed = true
        }
      }
    });
  },
  computed: {
      ...mapState('gifted', ['dataList'])
  },

  methods: {
      // 获取流程菜单
      async getFlowTreeFun() {
          try {
              const {
                  plan_id,
                  flow_task_id,
                  order_id,
                  order_task_id,
                  is_my,
                  task_id,
                  crm_token,
              } = this.$route.query;
              const params = {
                  plan_id,
                  flow_task_id,
                  order_id,
                  order_task_id,
                  is_my,
                  task_id,
              };
              const { code, data } = await getFlowTree({
                  params,
                  config: { Token: crm_token },
              });
              if (code === 200) {
                  this.treeData = data;
              }
          } catch (err) {
              console.error("error:", err);
          }
      },
      // 是否合成
      syntheticChange(item) {
          this.postData(item);
      },
      async toView(item) {
          if (this.$utils.isImageType(item.fileUrl)) {
              window.open(this.$utils.toOss(item.fileUrl), "_blank");
          } else {
              window.open(
                  await this.$utils.previewUrl(item.fileUrl, item.fileName),
                  "_blank"
              );
          }
      },
      download(item) {
        fileService.downloadFileByUrl(item.fileUrl, item.fileName)
      },
      editInfo(item) {
          item.isEdit = true;
          this.oldData = JSON.parse(JSON.stringify(this.data.works));
          this.$forceUpdate();
      },
      // 保存成功
      async saveInfo(item, index) {
          const edit = this.$refs["editForm" + index][0].save();
          Promise.all([edit]).then(
              () => {
                  //全部验证通过就会走这里
                  this.postData(item, index);
              },
              (err) => {
                  this.$utils.scrollIntoError(this);
              }
          );
          this.$utils.emptyModuleRed(this, "pdf-bg7");
      },
      async postData(item, index) {
          this.isLoading = true;
          try {
              // 文书更新修改发现的以前的bug，不显示‘高管证明文件’，但是保存的时候还把值传给了后台，为了不改变原流程逻辑，只在保存的时候‘professional_level = 1’时，将‘executives_certificate’字段置为空
              this.data.works.map(item => {
                  if (item.professional_level !== 1) {
                      item.executives_certificate = ''
                  }
                  return item
              })
              const { code, data } = await postClientInfo(this.data);
              if (code === 200) {
                  this.$message.success("保存成功！");
                  await this.$utils.saveGiftedStatus(this.$route.params.id);
                  // 新增后再编辑的数据是旧数据，重新赋值到最新数据
                  this.data = this.dataList[4]
                  item.isEdit = false;
                  this.oldData[index].isEdit = false
                  // 文书有更新，需要弹窗提示
                  // 没有到第二个流程的不弹窗
                  if (this.treeData.length < 2) return;
                  if (data.update_document && data.update_document.length) {
                      this.dialogVisible = true;
                      this.isUpdateDocument = true;
                      this.updateDocument = data.update_document.filter(
                          (item) =>
                              item.past_work_certificate &&
                              item.new_work_certificate
                      );
                      this.addDocument = data.update_document.filter(
                          (item) =>
                              !item.past_work_certificate &&
                              item.new_work_certificate
                      );
                      this.isDeleteDocument = false;
                      this.deleteDocument =
                          data.delete_document && data.delete_document.length
                              ? data.delete_document
                              : null;
                  } else if (
                      data.delete_document &&
                      data.delete_document.length
                  ) {
                      this.dialogVisible = true;
                      this.isUpdateDocument = false;
                      this.isDeleteDocument = true;
                      this.deleteDocument = data.delete_document;
                  }
              }
          } finally {
              this.isLoading = false;
          }
      },
      async del(index) {
          this.data.works.splice(index, 1);
          this.$emit("update:loading", true);
          try {
              this.data.next = 0;
              const { code, data } = await postClientInfo(this.data);
              if (code === 200) {
                  this.$message.success("操作成功！");
                  await this.$utils.saveGiftedStatus(this.$route.params.id);
                  this.oldData = JSON.parse(JSON.stringify(this.data.works));
                  // 没有到第二个流程的不弹窗
                  if (this.treeData.length < 2) return;
                  if (
                      data.delete_document &&
                      data.delete_document.length
                  ) {
                      this.dialogVisible = true;
                      this.isUpdateDocument = false;
                      this.isDeleteDocument = true;
                      this.deleteDocument = data.delete_document;
                  }
              }
          } finally {
              this.$emit("update:loading", false);
          }
      },
      cancel(index) {
          this.data.works = JSON.parse(JSON.stringify(this.oldData));
          if (this.data.works[index]) {
              this.data.works[index].isEdit = false;
              this.oldData[index].isEdit = false
            }
          this.$forceUpdate();
          this.$utils.emptyModuleRed(this, "pdf-bg7");
      },
      // 文书更新确认
      confirmTheUpdate() {
          if (this.deleteDocument) {
              this.isUpdateDocument = false;
              this.isDeleteDocument = true;
              this.updateDocument = null;
          } else {
              this.dialogVisible = false;
          }
      },
      beforeCloseDialog(done) {
          // console.log("----close");
          // done();
          if (this.isDeleteDocument) {
              this.dialogVisible = false;
              this.updateDocument = [];
              this.adDocument = [];
              this.deleteDocument = null;
              this.isDeleteDocument = false;
          } else {
              this.confirmTheUpdate();
          }
      },
      // 前往清单处理
      goToTheList() {
          this.dialogVisible = false;
          const { order_id, client_id, customer_id } = this.$route.query;
          const configurationList = this.treeData[1].children.find(
              (item) => item.key === "Configuration_list"
          );
          const order_task_id = configurationList
              ? configurationList.order_task_id
              : "";
          const message = {
              url: window.location.href,
              origin: "USER_Info",
              to: "/Workbench/DocumentsProduced/ConfigurationList",
              key: 'Configuration_list',
              order_id,
              order_task_id,
              client_id,
              customer_id,
          };
          window.parent.postMessage(JSON.stringify(message), "*");
      },
      // 删除文书
      async deleteDocumentFun() {
          try {
              const ids = this.deleteDocument.map((li) => li.id);
              const params = {
                  order_id: this.$route.query.order_id,
                  ids,
              };
              const config = {
                  Token: this.$route.query.crm_token,
              };
              const { code, msg } = await configurationListDel({
                  params,
                  config,
              });
              if (code === 200) {
                  this.dialogVisible = false;
                  this.isUpdateDocument = false;
                  this.isDeleteDocument = false;
                  this.$message.success(msg || "删除成功");
              }
          } catch (err) {
              console.log("--err");
          }
      },

        // 更新父组件数据
      resetData() {
        this.data = this.initData
      },
  },
};
</script>
<style lang="less" scoped>
.box-title {
  padding: 8px 15px;
  background: #f2f2f2;
  color: #000;
  display: flex;
  align-items: center;

  .tit {
      font-weight: bold;
  }

  .synthetic {
      padding: 0 20px;
      flex: 1;
  }

  .title-btn {
      float: left;
  }
}

.text_line {
    color: #000 !important;
    overflow: hidden;
    text-overflow: ellipsis;
    /* -webkit-box 实现多行文本 */
    display: -webkit-box;
    -webkit-box-orient: vertical;/*纵向对其*/
    line-height: 1.5;
    -webkit-line-clamp: 3;/*设置行数*/
    padding: 0 8px;
    &:hover {
        background-color: #e7f6ff;
    }
}

.file-con {
  display: flex;
  flex-direction: column;
}

.clu .el-col {
  align-items: flex-start !important;
}

.update {
  width: 100%;

  .update-title {
      width: 100%;
      height: 34px;
      line-height: 34px;
      text-indent: 12px;
      color: #141222;
      font-weight: 600;
      font-size: 16px;
      position: relative;
      background: linear-gradient(
              0deg,
              rgba(64, 158, 255, 0.06),
              rgba(64, 158, 255, 0.06)
          ),
          #ffffff;
      border-radius: 4px;
      margin-top: 20px;

      &::before {
          content: "";
          width: 3px;
          height: 16px;
          background-color: #409eff;
          position: absolute;
          top: 9px;
          left: 0;
      }
  }

  .document-name {
      width: 100%;
      display: flex;
      height: 42px;
      line-height: 42px;
      color: #000000;
      font-weight: 600;
      font-size: 16px;
      align-items: center;

      span {
          display: block;
          width: 20px;
          height: 20px;
          line-height: 18px;
          text-align: center;
          border: 1px solid #00b212;
          border-radius: 4px;
          background: rgba(0, 179, 18, 0.08);
          color: #00b312;
          font-weight: 400;
          font-size: 13px;
          margin-left: 12px;
      }
  }

  .update-name {
      width: 100%;
      height: 42px;
      line-height: 42px;
      display: flex;
      color: #6b6b6b;
      font-weight: 600;
      font-size: 16px;
      align-items: center;
      .old-name,
      .new-name {
          flex: 1;
          height: 42px;
          line-height: 42px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
      }
      .new-name {
          color: #000000;
      }

      .arrow {
          width: 34px;
          height: 6px;
          background-image: url("../../../../../../../assets/img/vector.png");
          background-repeat: no-repeat;
          background-size: cover;
          margin: 0 16px;
      }
  }

  .delete-tip {
      width: 100%;
      height: 24px;
      line-height: 24px;
      margin-top: 20px;
      font-weight: 600;
      font-size: 16px;
      color: #000000;
  }

  li.delete-item {
      height: 22px;
      line-height: 22px;
      font-weight: 600;
      font-size: 16px;
      margin-top: 22px;
      color: #ff0000;
  }
}

/deep/.el-dialog__body {
  padding: 0 20px 16px;
}
</style>

<style>
.tool-tip {
  max-width: 940px;
}
</style>
