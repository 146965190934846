<!--
 * @Author: zhai
 * @Description: 语言能力
-->
<template>
    <div class="details-module">
        <Container title="语言能力" padding="0" id="language-top">
            <div>
                <div class="description-box" id="language-top-score">
                    <p>{{ data.component_score.language.answer }}，得分<span class="text-red">{{ data.component_score.language.score }}</span>分</p>
                </div>
                <div style="padding: 15px">
                    <p id="language-top-content">1、您的语言水平与下列那一项相符合：</p>
                    <div v-if="!isEdit">
                        <span class="mt-20">{{ option }}</span><span v-if="data.language_detail.level === 4" class="mt-20" style="color: red"> - {{ ruleForm.name }} / {{ ruleForm.remark }}</span>
                    </div>
                    <div v-if="isEdit" class="mt-10">
                        <el-form :model="language" ref="ruleForm" inline label-width="0">
                            <el-form-item label="" prop="level" :rules="[{required: true,message: '请选择',trigger: 'change'}]">
                                <el-select v-model="language.level" placeholder="请选择" style="width: 450px">
                                    <el-option label="中文及英文-国外大学（英语国家）毕业，英文授课，获得学位证" :value="1"></el-option>
                                    <el-option label="中文及英文-有近两年的雅思或托福成绩单" :value="2"></el-option>
                                    <el-option label="中文及另一种外语" :value="4"></el-option>
                                    <el-option label="仅中文" :value="5"></el-option>
                                    <el-option label="仅英文" :value="6"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item v-if="language.level === 4" label="" prop="name" label-width="0" :rules="[{required: true, message: '请输入',trigger: 'blur'}]">
                                <el-input v-model="language.name" placeholder="请输入中文之外的语种"></el-input>
                            </el-form-item>
                            <el-form-item v-if="language.level === 4" label="" prop="remark" label-width="0" :rules="[{required: true,message: '请输入',trigger: 'blur'}]">
                                <el-input v-model="language.remark" placeholder="请输入2年内获取的证书及分数"></el-input>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </div>
            <div slot="handle">
                <div class="handle-btn mr-15">
                    <el-button v-if="!isEdit" type="primary" @click="editInfo()">编辑</el-button>
                    <el-button v-if="isEdit" @click="cancel">取消</el-button>
                    <el-button v-if="isEdit" :loading="isLoading" type="primary" @click="saveSuccess">保存</el-button>
                </div>
            </div>
        </Container>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import datas from '../../../../../Form/Gifted/Other/data'
import { postClientInfo } from '../../../../../../api/gifted'
export default {
    props: {
        data: {
            type: Object,
            default:() => {}
        },
    },
    data() {
        return {
            languageList: datas.languageList,
            option: '',
            isEdit: false,
            ruleForm: {},
            oldData: {},
            isLoading: false,
        }
    },
    computed: {
        ...mapState('previewPdfGifted', ['language'])
    },
    mounted() {
        let labels = datas.languageList.filter((v) => v.id === this.data.language_detail.level)[0];
        this.option = (labels?.tit || '') + (labels.dec ? '-' + labels.dec : '')
        this.ruleForm = this.data.language_detail;
        this.$store.dispatch('previewPdfGifted/setStateKeyData', { key: 'language', data: JSON.parse(JSON.stringify(this.data.language_detail)) })
    },
    methods: {
        editInfo() {
            this.isEdit = true;
            this.oldData = JSON.parse(JSON.stringify(this.data.language_detail));
        },
        async saveSuccess() {
            this.$refs.ruleForm.validate(async valid => {
                if (valid) {
                    this.data.language_detail = this.language;
                    try {
                        this.isLoading = true;
                        const { code, data } = await postClientInfo(this.data)
                        if (code === 200) {
                            this.$message.success("保存成功！");
                            this.isEdit = false;
                            this.data.component_score = data.component_score;
                            let labels = datas.languageList.filter((v) => v.id === data.language_detail.level)[0];
                            this.option = labels.tit + (labels.dec ? '-' + labels.dec : '')
                            this.$store.dispatch('previewPdfGifted/setStateKeyData', { key: 'language', data: JSON.parse(JSON.stringify(this.data.language_detail)) })
                        }
                        // 清空标红
                        this.$utils.emptyModuleRed(this, 'pdf-bg10')
                    } finally {
                        this.isLoading = false
                    }
                }
            })
        },
        cancel() {
            this.isEdit = false;
            this.ruleForm = JSON.parse(JSON.stringify(this.oldData));
            this.$store.dispatch('previewPdfGifted/setStateKeyData', { key: 'language', data: JSON.parse(JSON.stringify(this.oldData))})
            // 清空标红
            this.$utils.emptyModuleRed(this, 'pdf-bg10')
            this.$forceUpdate();
        }
    },
}
</script>

<style lang="less" scoped>
.container {
    position: relative;
    .title-btn {
        position: absolute;
        top: 4px;
        right: 15px;
    }
}
#language-top-content{
    color: #999;
    margin-bottom: 20px
}
</style>
