import { render, staticRenderFns } from "./EditQualifications.vue?vue&type=template&id=50873bb4&scoped=true&"
import script from "./EditQualifications.vue?vue&type=script&lang=js&"
export * from "./EditQualifications.vue?vue&type=script&lang=js&"
import style0 from "./EditQualifications.vue?vue&type=style&index=0&id=50873bb4&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50873bb4",
  null
  
)

export default component.exports