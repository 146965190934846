<!--
 * @Author: zhai
 * @Description:工作经验
-->
<template>
    <div>
        <div class="description-box" id="works-top-score">
            <p>{{ data.component_score.history.answer }}，得分<span class="text-red">{{ data.component_score.history.score }}</span>分</p>
            <p class="mt-10">{{ data.component_score.international.answer }}，得分<span class="text-red">{{ data.component_score.international.score }}</span>分</p>
            <p class="mt-10">{{ data.component_score.famous_enterprise.answer }}，得分<span class="text-red">{{ data.component_score.famous_enterprise.score }}</span>分</p>
        </div>
        <!-- 回显信息 -->
        <Detail :initData="works" ref="detailResf" />
    </div>
</template>

<script>
import Detail from './C/Detail.vue';
import { mapState } from 'vuex';
export default {
    props: {
        data: {
            type: Object,
            default:() => {}
        },
    },
    components: {
        Detail,
    },
    computed: {
        ...mapState('previewPdfGifted', ['works'])
    },
    created() {
        // pdf预览逻辑
        this.$store.dispatch('previewPdfGifted/setStateKeyData', { key: 'works', data: JSON.parse(JSON.stringify(this.data)) })
    },
    data() {
        return {
        }
    },
    mounted() {
        console.log(this.data)
    },
    methods: {
        addWork(){
            const isEditNow = this.works.works.filter(item => item.isEdit === true)
            if(isEditNow.length > 0) {
                this.$message.error("请先保存正在编辑或者新增的工作信息！");
                return
            }
            this.works.works.push({
                //工作经历
                id: '',
                company_name: "", //雇主名称
                duty: "", //职责性质
                entry_time: "", //入职时间
                departure_time: "", //离职时间
                industry: "", //雇主所属机构性质
                location: {
                    //工作地点
                    area: [],
                    foreign: "",
                    country: "",
                },
                location_aboard: "", //工作地点国内国外  0-国内 1-国外
                professional_level: "", //职位水平(1.高层管理岗2.中层管理岗3.低层管理岗4.专业人士5.半专业人士6.其他)
                professionalOther: "",
                work_certificate: "", //工作证明文件
                is_executives: 0,
                is_famous_enterprise: 0,
                is_edit: 1,
                is_synthetic: 1, // 是否合成 1合成 2不合成
                company_member_total: '', //企业人数规模
                administrative_level: '', //行政架构层级
                company_manage_total: '', //下属管理人数
                turnover: '', // 公司营业额/港元（近一年）
                business_nature: '', // 公司业务性质/范畴/所属行业
                top_manager_date_checked: false, // 高管至今
                top_manager_date_start: "", // 高管开始时间
                top_manager_date_end: "", // 高管结束时间
                departure_time_checked: false, // 是否选中至今
                executives_certificate: "", //高管证明文件
                position: "", //职位
                remark: "", //备注
                wduty: null,
                highlights: null,
                file: [],
                key: Date.now(),
                not_need_upload_file: 0, // 是否与上份工作的“简历/工作总结”一致
                isEdit: true,
                master_of_important_project: null,
                match_listing: 0,
                international_work_experience: 0, // 相关工作属国际工作经验
                projects: [], // 工作经历
                business_remark: "",
            });
            this.$refs.detailResf.resetData()
            this.anchorPoint()
        },
        anchorPoint() {
            this.$nextTick(() => {
                const pageId = document.querySelector('#scrollWorks' + (this.works.works.length - 1))
                pageId.scrollIntoView({
                    block: "start",
                    behavior: "smooth",
                })
            })
        }
    }
}
</script>

<style lang="less" scoped>

</style>
