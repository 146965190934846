<!--
 * @Author: zhai
 * @Description: 居住信息
-->
<template>
    <div id="pdf-residences">
        <Container title="居住信息" padding="15px 15px 20px 15px">
            <p class="mt-10 mb-20">请按日期顺序列出你自出生后曾居住12个月及以上的所有国家或地区，包括现居、海外居住信息（如填写了海外工作经历请对应填写符合条件的信息）</p>
            <el-table
                v-if="datas.length > 0"
                :data="datas"
                border
                style="width: 100%"
                >
                <el-table-column
                    prop="country"
                    label="国家"
                    align="center"
                    id="pdf-country"
                >
                    <template slot-scope="scope">
                        <div v-if="!scope.row.isEdit">{{ scope.row.country }}</div>
                        <div v-if="scope.row.isEdit">
                            <CountrySelect v-model="scope.row.country" style="width: 100%;" />
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="start_time"
                    label="由（年/月）"
                    align="center"
                >
                    <template slot-scope="scope">
                        <div v-if="!scope.row.isEdit">{{ scope.row.start_time }}</div>
                        <div v-if="scope.row.isEdit" >
                            <el-date-picker v-model="scope.row.start_time" :picker-options="pickerBeginDateBefore" value-format="yyyy-MM" type="month" placeholder="请选择" />
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="end_time"
                    label="至（年/月）"
                    align="center"
                >
                    <template slot-scope="scope">
                        <div v-if="!scope.row.isEdit">{{scope.row.end_time_checked ? '至今' : scope.row.end_time }}</div>
                        <div v-if="scope.row.isEdit" style="display: flex">
                            <el-date-picker v-model="scope.row.end_time" :disabled="scope.row.end_time_checked" :picker-options="pickerBeginDateBefore" value-format="yyyy-MM" type="month" placeholder="请选择" />
                            <el-checkbox v-model="scope.row.end_time_checked" style="margin-left: 15px" @change="checkedChange(scope.row)">至今</el-checkbox>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="180">
                    <template slot-scope="scope">
                        <el-popconfirm v-if="!scope.row.isEdit" class="mr-15" placement="top" title="确定删除？" @confirm="del(scope.row, scope.$index)">
                            <el-button slot="reference">删除</el-button>
                        </el-popconfirm>
                        <el-button v-if="!scope.row.isEdit" type="primary" @click="edit(scope.row)">编辑</el-button>
                        <el-button v-if="scope.row.isEdit" @click="cancel(scope.row,scope.$index)">取消</el-button>
                        <el-button v-if="scope.row.isEdit" :loading="isLoading" type="primary" @click="save(scope.row, scope.$index)">保存</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="add-box">
                <div class="add-btn" @click="add">
                    <el-button type="text">+新增居住信息</el-button>
                </div>
            </div>
        </Container>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { postClientInfo } from '../../../../../api/gifted'
export default {
    props: {
        data: {
            type: Object,
            default:() => {}
        },
    },
    data() {
        return {
            datas: [], // 拷贝数据
            oldDatas:[],
            // 只能选择当天之前的日期
            pickerBeginDateBefore: {
                disabledDate(time) {
                    return time.getTime() >= Date.now() - 8.64e6;
                },
            },
            isLoading: false,
        }
    },
    computed: {
        ...mapState('previewPdfGifted', ['residences'])
    },
    created() {
        this.pdfOn()
        },
    mounted() {
        // pdf预览逻辑
        // this.$store.dispatch('previewPdfGifted/setStateKeyData', { key: 'residences', data: JSON.parse(JSON.stringify(this.data)) })
        this.datas = this.residences
    },
    methods: {
        pdfOn(){
            this.data.residences.forEach((item, i) => {
                this.$set(this.data.residences[i], 'isEdit', false)
            });
            this.oldDatas = JSON.parse(JSON.stringify(this.data.residences))
            // pdf预览逻辑
            this.$store.dispatch('previewPdfGifted/setStateKeyData', { key: 'residences', data: JSON.parse(JSON.stringify(this.data.residences)) })
            this.datas = this.residences
        },
        edit(item) {
            item.isEdit = true;
        },
        cancel(item,index) {
            this.pdfOn()
            if (this.data.residences[index]) {
                this.datas = JSON.parse(JSON.stringify(this.oldDatas));
                this.datas[index].isEdit = false;
            } else {
                this.datas.splice(index, 1);
            }
            this.$utils.emptyModuleRed(this, 'pdf-bg10')
        },
        async save(item,index) {
            if (!item.country) {
                return this.$message.error("请输入国家");
            }
            if (!item.start_time) {
                return this.$message.error("请选择开始时间");
            }
            if (!item.end_time_checked && !item.end_time) {
                return this.$message.error("请选择结束时间");
            }
            if (new Date(item.end_time).getTime() < new Date(item.start_time).getTime()) {
                return this.$message.error("开始时间不得在结束时间之后");
            }

            // 其他编辑状态下存储
            const eidtArr = []
            this.datas.forEach((k, i) => {
                if (i !== index && k.isEdit) eidtArr.push(k)
            })
            const copyDatas = JSON.parse(JSON.stringify(this.datas))
            eidtArr.forEach(v => {
                copyDatas.forEach((k, i) => {
                    if (!v.id && k._time === v._time) {
                        copyDatas.splice(i, 1)
                    }
                })
            })
            this.data.residences = copyDatas
            try {
                this.isLoading = true
                const { code, data } = await postClientInfo(this.data)
                if (code === 200) {
                    data.residences.forEach(item => {
                        item.isEdit = false
                    })
                    this.pdfOn()
                    this.$message.success("保存成功！");
                }
            } finally {
                this.isLoading = false
            }
            this.$utils.emptyModuleRed(this, 'pdf-bg10')
        },
        async del(item,index) {
            this.datas.splice(index, 1);
            this.data.residences = JSON.parse(JSON.stringify(this.datas))
            const { code } = await postClientInfo(this.data)
            if (code === 200) {
                this.$message.success("删除成功");
                item.isEdit = false;
                this.datas = JSON.parse(JSON.stringify(this.data.residences))
            }
        },
        add() {
            this.datas.push(
                {
                    country: "",
                    end_time: "",
                    start_time: "",
                    end_time_checked: false,
                    isEdit: true,
                    _time: new Date().getTime()
                }
            );

        },
        checkedChange(row) {
            if (row.end_time_checked) {
                row.end_time = '';
            }
        }
    },
}
</script>

<style lang="less" scoped>
    .add-box {
        background: white;
        padding: 20px;
        text-align: center;
        border: 1px solid #ebeef5;
        border-top: 0 none;
        .add-btn {
            border: 1px dashed #ddd;
            padding: 3px 0;
        }
    }
</style>
