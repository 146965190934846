<!--
 * @Author: filename
 * @Description: 学历证书姓名及出生日期正确性
-->

<template>
    <div class="info mt-10" v-if="!data.isEdit">
        <p class="title">1、您的所有学历及资格证书上的姓名及出生日期，是否与身份证完全一致 ？ （例如：身份证上是【张四】。学位证上是【张三】，则为不相同）</p>
        <p class="mt-10">{{ data.qualification == 1 ? '是' : '否' }}</p>
        <template v-if="dataList[0].married != 2 && data.spouseHasDegree != 0">
            <p class="mt-10 title">2、您配偶的大学毕业证和学位证上的姓名及出生日期，是否与身份证完全一致？</p>
            <p class="mt-10" v-show="data.graduate == 1">是</p>
            <p class="mt-10" v-show="data.graduate == 2">否</p>
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        ...mapState('gifted', ['dataList'])
    }
}
</script>

<style lang="less" scoped>
.info {
    padding: 10px;
    .title {
        color: #999;
        margin-bottom: 20px;
    }
}
</style>
