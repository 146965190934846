<!--
 * @Author: zhai
 * @Description: 主申请人-编辑
-->
<template>
    <div>
        <el-form ref="userForm" :model="userForm" :rules="rules" label-width="0">
            <!-- 主申请人 -->
            <div class="basics-box">
                <div class="basics-title">1、是否曾在香港或其他地方因任何罪行或违法行为被定罪</div>
                <div class="same-line">
                    <el-form-item prop="has_conviction.has">
                        <el-radio-group v-model="userForm.has_conviction.has">
                            <el-radio label="N">否</el-radio>
                            <el-radio label="Y">是</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item v-if="userForm.has_conviction.has === 'Y'" class="input-line" prop="has_conviction.info">
                        <el-input v-model.trim="userForm.has_conviction.info" maxlength="200" placeholder="如选择“是“，请详细说明：时间、地点、事件。" clearable />
                    </el-form-item>
                </div>
            </div>
            <div class="basics-box">
                <div class="basics-title">2、是否曾在香港或其他地方被拒入境／递解／遣送或要求离境？</div>
                <div class="same-line">
                    <el-form-item prop="has_departure.has">
                        <el-radio-group v-model="userForm.has_departure.has">
                            <el-radio label="N">否</el-radio>
                            <el-radio label="Y">是</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item v-if="userForm.has_departure.has === 'Y'" class="input-line" prop="has_departure.info">
                        <el-input v-model.trim="userForm.has_departure.info" maxlength="200" placeholder="如选择“是“，请详细说明：时间、地点、事件。" clearable />
                    </el-form-item>
                </div>
            </div>
            <div class="basics-box">
                <div class="basics-title">3、是否曾被拒绝签发香港或其他地方的签证／进入许可？</div>
                <div class="same-line">
                    <el-form-item prop="has_deny.has">
                        <el-radio-group v-model="userForm.has_deny.has">
                            <el-radio label="N">否</el-radio>
                            <el-radio label="Y">是</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item v-if="userForm.has_deny.has === 'Y'" class="input-line" prop="has_deny.info">
                        <el-input v-model.trim="userForm.has_deny.info" maxlength="200" placeholder="如选择“是“，请详细说明：时间、地点、事件。" clearable />
                    </el-form-item>
                </div>
            </div>
            <div class="basics-box">
                <div class="basics-title">4、是否曾触犯香港或任何地方的入境法例？</div>
                <div class="same-line">
                    <el-form-item prop="has_breaking_law.has">
                        <el-radio-group v-model="userForm.has_breaking_law.has">
                            <el-radio label="N">否</el-radio>
                            <el-radio label="Y">是</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item v-if="userForm.has_breaking_law.has==='Y'" class="input-line" prop="has_breaking_law.info">
                        <el-input v-model.trim="userForm.has_breaking_law.info" maxlength="200" placeholder="如选择“是“，请详细说明：时间、地点、事件。" clearable />
                    </el-form-item>
                </div>
            </div>
            <div class="basics-box">
                <div class="basics-title">5、是否曾经使用另一个姓名或身份申请香港入境签证/进入许可或进入香港？</div>
                <div class="same-line">
                    <el-form-item prop="has_other_id.has">
                        <el-radio-group v-model="userForm.has_other_id.has">
                            <el-radio label="N">否</el-radio>
                            <el-radio label="Y">是</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item v-if="userForm.has_other_id.has==='Y'" class="input-line" prop="has_other_id.info">
                        <el-input v-model.trim="userForm.has_other_id.info" maxlength="200" placeholder="如选择“是“，请详细说明：时间、地点、事件。" clearable />
                    </el-form-item>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script lang="javascript">
export default {
    name: "TheLord",
    props: {
        data: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            rules: {
                "has_conviction.has": [
                    {
                        required: true,
                        message: "请选择",
                        trigger: "change",
                    },
                ],
                "has_departure.has": [
                    {
                        required: true,
                        message: "请选择",
                        trigger: "change",
                    },
                ],
                "has_deny.has": [
                    {
                        required: true,
                        message: "请选择",
                        trigger: "change",
                    },
                ],
                "has_breaking_law.has": [
                    {
                        required: true,
                        message: "请选择",
                        trigger: "change",
                    },
                ],
                "has_other_id.has": [
                    {
                        required: true,
                        message: "请选择",
                        trigger: "change",
                    },
                ],
                
                "has_conviction.info": [
                    {
                        required: true,
                        message: "请选择",
                        trigger: "blur",
                    },
                ],
                "has_departure.info": [
                    {
                        required: true,
                        message: "请选择",
                        trigger: "blur",
                    },
                ],
                "has_deny.info": [
                    {
                        required: true,
                        message: "请选择",
                        trigger: "blur",
                    },
                ],
                "has_breaking_law.info": [
                    {
                        required: true,
                        message: "请选择",
                        trigger: "blur",
                    },
                ],
                "has_other_id.info": [
                    {
                        required: true,
                        message: "请选择",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    created() {
        this.userForm = this.data.applicant;
        console.log(this.userForm)
    },
    methods: {
        // 校验
        save () {
            let that = this;
            return new Promise(function (resolve, reject) {
                that.$refs.userForm.validate( valid => {
                    if (valid) {
                        resolve(valid)
                    } else {
                        reject();
                    }
                })
            })
        }
    },
};
</script>

<style lang="less" scoped>
.basics-box {
    padding: 0 24px;
    color: #606266;
    font-size: 14px;

    .basics-title {
        margin-bottom: 9px;
        position: relative;
        padding-left: 12px;

        &::before {
            content: "*";
            position: absolute;
            left: 0;
            top: 3px;
            color: red;
        }
    }

    .basics-title.no-tip {
        padding-left: 0;

        &::before {
            content: "";
        }
    }
}

.same-line {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 47px;

    .el-radio {
        margin-right: 40px;

        &:last-child {
            margin-right: 0;
        }
    }

    .input-line {
        width: 782px;
    }

    /deep/ .el-form-item__content {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }

    .el-radio-group {
        width: 140px;
    }
}
</style>
