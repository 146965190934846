<!--
 * @Author: filename
 * @Description: 家庭成员-配偶信息
-->
<template>
    <Container title="配偶" padding="0" id="pdf-spouse">
        <div slot="handle" v-if="!data.spouse">
            <div class="handle-btn">
                <el-button @click="addSpouse(1)">新增-随行</el-button>
                <el-button @click="addSpouse(2)">新增-不随行</el-button>
            </div>
        </div>
        <div class="description-box" id="pdf-spouse-score">
            <p>{{ data.component_score.spouse.answer }}，得分<span class="text-red">{{data.component_score.spouse.score}}</span>分；</p>
        </div>
        <template v-if="data.spouse">
            <div class="title-box">
                <span>
                    <el-tooltip v-if="this.data.spouse.finish_follow == 1" class="item" effect="dark" placement="top-start">
                        <span class="tooltip">补</span>
                        <template slot="content">
                            <div class="content">
                                <div>创建人：{{data.spouse.supplement_info.operator_name}}</div>
                                <div>加入时间：{{data.spouse.supplement_info.created_at}}</div>
                                <div>主合同任务：{{data.spouse.supplement_info.order_task_name}}</div>
                            </div>
                        </template>
                    </el-tooltip>
                    配偶信息
                    &nbsp;&nbsp;
                    <el-switch
                        @change="followChange"
                        active-text="随行"
                        inactive-text="不随行"
                        v-model="data.spouse.follow"
                        id="spouse-follow"
                        :active-value="1"
                        :inactive-value="2">
                    </el-switch>

                    <div v-if="data.spouse.follow == 1" style="margin-left: 50px; display: inline-block; font-size: 14px;">
                        是否添加在《受养人声明》：
                        <el-switch
                            @change="statementChange"
                            active-text="添加"
                            inactive-text="不添加"
                            v-model="data.spouse.statement"
                            :active-value="1"
                            :inactive-value="0">
                        </el-switch>
                    </div>

                </span>
                <div class="title-btn">
                    <el-button v-if="data.spouse.isEdit" :loading="isLoading" @click="cancel(data.spouse)">取消</el-button>
                    <el-button v-if="!data.spouse.isEdit" type="primary" @click="editInfo(data.spouse)">编辑</el-button>
                    <el-button v-if="data.spouse.isEdit" type="primary" :loading="isLoading" @click="checkInfo">保存</el-button>
                </div>
            </div>
            <!-- 回显信息 -->
            <SpouseDetail v-if="!data.spouse.isEdit" :data="data.spouse" />
            <!-- 编辑 -->
            <EditSpouse v-loading="isLoading" ref="editSopuse" v-if="data.spouse.isEdit" :data.sync="data.spouse" />
        </template>
        <div v-else class="no-data">暂无数据</div>
    </Container>
</template>

<script>
import { mapState } from 'vuex'
import SpouseDetail from './C/SpouseDetail.vue'
import EditSpouse from './C/EditSpouse.vue'
import { postClientInfo } from '../../../../../../api/gifted'
export default {
    props: {
        data: {
            type: Object,
            default:() => {}
        },
    },
    components: {
        SpouseDetail,
        EditSpouse
    },
    computed: {
        ...mapState('previewPdfGifted', ['family']),
        ...mapState('gifted', ['dataList'])
    },
    data() {
        return {
            isLoading: false
        }
    },
    created() {
        if (this.data.spouse) {
            if(this.data.spouse.follow == 1) {
                this.data.spouse.statement = 1
            }
            this.$set(this.data.spouse, 'isEdit', false)
            // pdf预览逻辑
            this.$store.dispatch('previewPdfGifted/setFamilyKeyArr', { key: 'spouse', data: JSON.parse(JSON.stringify(this.data.spouse)) })
        }
    },
    methods: {
        // 配偶 type 1 随行 2不随行
        addSpouse(type) {
            this.data.spouse =  {
                isEdit: true,
                subset_name_pinyin: {
                    family_name: '',
                    given_name: ''
                },
                relation: 3,
                nationality: '',
                follow: type,
                subset_name: '',
                used_name: '',
                birthday: '',
                birth_place_aboard: 0,
                birth_place: {
                    area: [],
                    country: '',
                    details: '',
                    foreign: ''
                },
                occupation: '',
                occupation_name: '',
                child_has_hk_id: {
                    has: ''
                },
                hk_identity_card: '',
                // 随行
                has_degree: '',
                has_degree_json: [
                    {
                        degree: '',
                        address: ''
                    }
                ],
                certificates: {
                    cn_identity_card: {
                        img_back: '',
                        img_front: '',
                        issue_at: '',
                        issue_date_end_at: '',
                        issue_date_start_at: '',
                        number: ''
                    },
                    hk_macao_pass: {
                        img_back: '',
                        img_front: '',
                        issue_at: '',
                        issue_date_end_at: '',
                        issue_date_start_at: '',
                        number: ''
                    },
                    passport: {
                        img_back: '',
                        img_front: '',
                        issue_at: '',
                        issue_date_end_at: '',
                        issue_date_start_at: '',
                        number: ''
                    }
                },
                nows: '',
                is_handled: '',
                address: {
                    area: [],
                    country: '',
                    details: '',
                    foreign: ''
                },
                child_in_hk: {
                    has: '',
                    info: ''
                },
                electronic_visa_date: '',
                hk_identity: '',
                //
                permanent_residence: 0,
                permanent_reside_year: '',
                permanent_reside_month: '',
            }
        },
        // 是否随行
        followChange(v) {
            if (v == 2) {
                this.data.spouse.statement = 0
            }else {
                this.data.spouse.statement = 1
            }
            this.saveInfo(v)
        },
         // 是否添加受养人声明
        statementChange (v) {
            this.saveInfo(v)
        },
        // 保存
        async saveInfo(v) {
            if (typeof v != 'number') {
                this.data.spouse = this.$refs.editSopuse.ruleForm
            }
            try {
                if (this.data.spouse.certificates.hk_macao_pass.number) {
                    this.data.spouse.is_handled = 1
                }
                this.isLoading = true
                const { code, data } = await postClientInfo(this.data)
                if (code === 200) {
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    })
                    this.data.spouse.isEdit = false
                    this.data.spouse = data.spouse
                    this.data.component_score = data.component_score
                    this.$set(this.data.spouse, 'isEdit', false)
                    this.$store.dispatch('previewPdfGifted/setFamilyKeyArr', { key: 'spouse', data: JSON.parse(JSON.stringify(this.data.spouse)) })
                    // 实现pdf实时预览，勿动
                    this.dataList[1].component_score = data.component_score
                }
            } finally {
                this.isLoading = false
            }
        },
        // 编辑
        editInfo(item) {
            item.isEdit = true
        },
        // 保存
        checkInfo() {
            Promise.all([this.$refs.editSopuse.checkParm()])
            .then(async () => {
                this.saveInfo()
            })
            .catch((err) => {
                console.log(err)
                setTimeout(() => {
                    this.$utils.scrollIntoError(this)
                }, 100)
            })
            this.$utils.emptyModuleRed(this, 'pdf-bg2')
        },
        // 取消
        cancel(item) {
            this.$store.dispatch('previewPdfGifted/setFamilyKeyArr', { key: 'spouse', data: JSON.parse(JSON.stringify(this.data.spouse)) })
            if (item.id) {
                item.isEdit = false
            } else {
                this.data.spouse = null
            }
            // 清空标红
            this.$utils.emptyModuleRed(this, 'pdf-bg2')
        }
    }
}
</script>

<style lang="less" scoped>
.tooltip{
    display: inline-block;
    border-radius: 100%;
    background: #1296db;
    color: #fff;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border: 1px #377395 solid;
    // height: 100%;
}
.content{
    padding: 3px;
    div{
        margin: 10px 0;
    }
}
</style>
