<!--
 * @Author: Jacob.Zhang
 * @Description: 个人信息表 - 主申请人信息
-->
<template>
    <!-- 回显信息 -->
    <div ref="userDetail" class="details-module common-part">
        <!-- 主申请人信息 -->
        <el-row :gutter="15">
            <el-col :span="12" id="username">
                <span>主申姓名：</span>
                <span>{{ info.username }}</span>
            </el-col>
            <el-col :span="12" id="used_name">
                <span>曾用名：</span>
                <span>{{ info.used_name }}</span>
            </el-col>
            <el-col :span="12" id="used_name">
                <span>婚前姓氏：</span>
                <span>{{ info.surname || "-"  }}</span>
            </el-col>
            <el-col :span="12" id="username_pinyin">
                <span>姓名拼音：</span>
                <span>{{ info.username_pinyin.family_name + ' ' + info.username_pinyin.given_name }}</span>
            </el-col>
            <el-col :span="12" id="user_sex">
                <span>性别：</span>
                <span>{{ info.sex | _sex }}</span>
            </el-col>
            <el-col :span="12" id="user_birthday">
                <span>出生日期：</span>
                <span>{{ `${info.birthday}` }}（<span class="text-red">{{ `${dataList[0].age}岁` }}</span>）</span>
            </el-col>
            <el-col :span="12" id="birth_place_aboard">
                <span>出生城市：</span>
                <!-- birth_place_aboard 0为国内 1为国外 -->
                <span>{{ info.birth_place_aboard === 0 ? info.birth_place.area.join('') : info.birth_place.foreign }}</span>
            </el-col>
            <el-col :span="12" id="user_mobile">
                <span>手机号：</span>
                <span>{{ info.mobile }}</span>
            </el-col>
            <el-col :span="12">
                <span>邮箱：</span>
                <span>{{ info.email }}</span>
            </el-col>
            <el-col :span="12" id="user_married">
                <span>婚姻状况：</span>
                <span>{{ info.married | _married }}</span>
            </el-col>
            <el-col :span="12" id="user_nationality">
                <span>国籍：</span>
                <span>{{ info.nationality }}</span>
            </el-col>
            <el-col :span="inChina ? 24 : 12" id="user_address">
                <span>现居住地址：</span>
                <span id="user_address_detail">{{ info.address.country + '-' + (inChina ? (info.address.area.join('') + info.address.details) : info.address.foreign) }}</span>
            </el-col>
            <el-col :span="12" id="user_address" v-if="!inChina">
                <span>是否居住满一年及以上：</span>
                <span>{{ info.is_live_oversea_year === 1 ? '是' : '否' }}</span>
            </el-col>
            <el-col :span="12">
                <span>是否办理过香港身份证：</span>
                <span class="text-red">{{ info.has_hk_id | hasHkid }}</span>
            </el-col>
            <el-col :span="12" id="user_hk_id_number">
                <span>香港身份证号码：</span>
                <span>{{ info.has_hk_id === 1 ? info.hk_id_number : '-' }}</span>
            </el-col>
        </el-row>
        <!-- 证件 -->
        <template v-for="(item, key) in info.certificates">
            <el-row :key="key" :gutter="15">
                <el-divider />
                <el-col :span="24" :id="`${key}_name`">
                    <span>证件类别：</span>
                    <span v-if="key === 'passport'">{{ item.passport_type | _passportType }}</span>
                    <span v-else>{{ key | _certificates }}</span>
                </el-col>
                <el-col :span="12" :id="`${key}_number`">
                    <span>证件号码：</span>
                    <span>{{ item.number }}</span>
                </el-col>
                <el-col :span="12" :id="`${key}_issue_at`">
                    <span>签发地：</span>
                    <span>{{ item.issue_at }}</span>
                </el-col>
                <el-col :span="12" :id="`${key}_issue_date_start_at`">
                    <span>签发日期：</span>
                    <span>{{ item.issue_date_start_at }}</span>
                </el-col>
                <el-col :span="12" :id="`${key}_issue_date_end_at`">
                    <span>届满日期：</span>
                    <span>{{ item.issue_date_end_at }}</span>
                </el-col>
            </el-row>
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    filters: {
        hasHkid(val) {
            switch (val) {
                case 0:
                    return '否'
                case 1:
                    return '是'
                default:
                    return '-';
            }
        }
    },
    props: {
        info: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    computed: {
        ...mapState('gifted', ['dataList']),
        inChina() {
            console.log(this.info);
            return !!(this.info.address.country && this.info.address.country.includes('中国'))
        }
    }
}
</script>

<style lang="less" scoped>
#user_address {
    align-items: flex-start;

    &_detail {
        padding-right: 15px;
    }
}
</style>
